@function theme_color($key: 'primary') {
  @return map_get($theme-color, $key);
}

@function theme_text_color($key: 'primary') {
  @return map_get($text-color, $key);
}

@function font_size_rem($size: $base-font-size, $size-default: $base-font-size ) {
  @return ($size / $size-default * 1rem);
}

@function font_size_em($size: $base-font-size, $size-default: $base-font-size ) {
  @return ($size / $size-default * 1em);
}

/// Replace `$search` with `$replace` in `$string`
/// @author Hugo Giraudel
/// @param {String} $string - Initial string
/// @param {String} $search - Substring to replace
/// @param {String} $replace ('') - New value
/// @return {String} - Updated string
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}
