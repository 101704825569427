input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  border: none;
  background: none;
  outline: none;
  background: #fff;
  border: none;
  box-sizing: border-box;
  border-radius: 2px;
  font-size: 16px;
  font-weight: 400;
  color: #000;
}
input {
  padding: 10px;
  padding-left: 10px;
}

input[type="radio"] {
  padding: 0;
  margin: 0;
  height: 15px;
  width: 15px;
}

textarea {
  padding: 20px;
  padding-left: 30px;
  resize: none;
}

// ================================= Main Form =========================== //

.main-form {
  .form-enter {
    border-bottom: 2px solid #fff;
    padding-bottom: 1.8rem;
  }
  .radio-wrap {
    width: 100%;
    @include minMedia(768) {
      width: 50%;
    }
  }
  .input-field {
    margin-top: 5px;
    &:first-child {
      margin-top: 0;
    }
  }
  input[type="text"],
  input[type="tel"],
  input[type="number"],
  input[type="email"] {
    height: 34px;
    line-height: 34px;
  }
  select {
    width: 100%;
    height: 34px;
    @include minMedia(768) {
      width: 50%;
    }
  }
}

.custom-radio {
  display: flex;
  align-items: center;
  cursor: pointer;
  &__input-shadow {
    width: 16px;
    height: 16px;
    border-radius: 100%;
    border: 1px solid;
    position: relative;
    display: inline-block;
    margin-right: 5px;
    flex-shrink: 0;
    &:before {
      content: "";
      display: none;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background: #f00a0a;
      position: absolute;
      left: 3px;
      top: 3px;
    }
  }
  input {
    display: none;
    &.input-radio {
      position: absolute;
      top: 2px;
      left: 0;
    }
  }
}

input:checked + .custom-radio__input-shadow:before {
  display: block;
}

// ================================= Order Form =========================== //

.order-form {
  input[type="text"],
  input[type="email"],
  input[type="tel"] {
    height: 60px;
    line-height: 60px;
    width: 100%;
    color: #7b7d80;
    @include minMedia(576) {
      max-width: 270px;
    }
  }
  .form-enter {
    max-width: 320px;
    margin: 0 auto;
    @include minMedia(576) {
      max-width: none;
    }
  }
}

.terms {
  display: inline-block;
  position: relative;
  cursor: pointer;
  input {
    display: none;
  }
  margin-top: 0.7rem;
  @include minMedia(1200) {
    padding-right: 12px;
  }
  &__checkbox {
    display: inline-flex;
    @include square(0.7em);
    border: 1px solid currentColor;
    position: relative;
    text-align: center;
    align-items: center;
    justify-content: center;
    svg {
      vertical-align: middle;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
    }
    & input:checked + svg {
      opacity: 1;
      visibility: visible;
    }
  }
  &__text {
    display: block;
    font-size: 15px;
    line-height: 20px;
    a {
      text-decoration: none;
      transition: all 0.5s ease;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

// ================================= Modal Order =========================== //

.modal-order {
  max-width: 320px;
  margin: 0 auto;
  input[type="text"],
  input[type="email"],
  input[type="tel"] {
    height: 60px;
    line-height: 60px;
    width: 100%;
    color: #7b7d80;
  }
  .form-enter {
    width: 100%;
  }
}

// ================================= Modal Order =========================== //

.success-form {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background: #053e7c;
  z-index: 1000;
  opacity: 0;
  &.success-mail {
    height: 100%;
    opacity: 1;
  }
}
