@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: local("Montserrat"), url(~fonts/Montserrat/Montserrat-Light.ttf) format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local("Montserrat"), url(~fonts/Montserrat/Montserrat-Regular.ttf) format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: local("Montserrat"), url(~fonts/Montserrat/Montserrat-Medium.ttf) format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: local("Montserrat"), url(~fonts/Montserrat/Montserrat-SemiBold.ttf) format("TrueType");
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local("Montserrat"), url(~fonts/Montserrat/Montserrat-Bold.ttf) format("TrueType");
}

@font-face {
  font-family: "Opensans";
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: local("Opensans"), url(~fonts/Opensans/OpenSans-Light.ttf) format("TrueType");
}
@font-face {
  font-family: "Opensans";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local("Opensans"), url(~fonts/Opensans/OpenSans-Regular.ttf) format("TrueType");
}
@font-face {
  font-family: "Opensans";
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: local("Opensans"), url(~fonts/Opensans/OpenSans-SemiBold.ttf) format("TrueType");
}
@font-face {
  font-family: "Opensans";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local("Opensans"), url(~fonts/Opensans/OpenSans-Bold.ttf) format("TrueType");
}

html {
  font-size: 14px;
  @include minMedia(768) {
    font-size: $base-font-size;
  }
}
body {
  font-family: $text-font-stack;
  color: theme_text_color("primary");
  font-size: 14px;
  font-weight: 400;
  line-height: $base-line-height;
  @include minMedia(768) {
    font-size: $base-font-size;
  }
}
@include placeholderColor(inherit);

.input-error,
.form-error {
  color: theme_text_color("error");
  font-size: 13px;
}
h1 {
  font-family: "Opensans";
  font-weight: 600;
  span {
    font-family: "Montserrat";
  }
}
h2,
h3 {
  font-family: "Montserrat";
  font-weight: 700;
}
.h1 {
  line-height: 1.363;
  position: relative;
  font-size: 16px;
  @include minMedia(768) {
    font-size: font_size_rem(18px);
  }
  @include minMedia(992) {
    font-size: font_size_rem(20px);
  }
  @include minMedia(1200) {
    font-size: font_size_rem(22px);
  }
}
.h2 {
  position: relative;
  font-size: 18px;
  @include minMedia(768) {
    font-size: font_size_rem(22px);
  }
  @include minMedia(992) {
    font-size: font_size_rem(28px);
  }
  @include minMedia(1200) {
    font-size: font_size_rem(32px);
  }
  span {
    position: relative;
  }
}
.h3 {
  line-height: 1.22;
  position: relative;
  font-size: font_size_rem(18px);
  font-weight: 600;
}

.strike {
  text-decoration: line-through;
}
