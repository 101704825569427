.s-top {
  overflow: hidden;
  padding-bottom: 2.8rem;
  .section-content {
    padding-bottom: 1.5rem;
    @include minMedia(768) {
      padding-bottom: 2rem;
    }
    @include minMedia(992) {
      padding-bottom: 2.8rem;
    }
  }
  .deco-line {
    &:before {
      width: 100%;
      @include minMedia(576) {
        left: 0;
        width: 70%;
        max-width: 470px;
      }
    }
  }
  .head-bg {
    &--decstop {
      display: none;
      position: absolute;
      top: -15px;
      left: 0;
      width: 100%;
      height: 280px;
      @include minMedia(576) {
        display: block;
        top: -15px;
        height: 450px;
        display: none;
      }
      @include minMedia(768) {
        display: block;
        top: -15px;
        height: 400px;
      }
      @include minMedia(992) {
        top: 0;
        height: 450px;
      }
      @include minMedia(1200) {
        top: 20px;
      }
    }
    &--mobil {
      display: block;
      position: relative;
      width: 100%;
      height: auto;
      padding-top: 70%;
      @include minMedia(576) {
        display: none;
      }
    }
    &.bg-cover {
      background-position: right;
      @include minMedia(768) {
        background-position: center;
      }
      @include minMedia(1200) {
        background-position: right;
      }
    }
  }
}

.menu-wrap {
  .menu-item {
    margin-right: 1.2rem;
    font-weight: 500;
    &:last-child {
      margin-right: 0;
    }
    @include minMedia(1200) {
      margin-right: 1.9rem;
    }
  }
}

// ======================================================================

.s-calculation {
  height: auto;
  padding: 35px 0 30px 0;
  @include minMedia(576) {
    padding: 42px 0 35px 0;
  }
  .form-wrap {
    max-width: 654px;
    margin-top: 0.8rem;
    @include minMedia(768) {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }
  }
}

// ======================================================================

.s-advantages {
  height: auto;
  .h2 {
    @include minMedia(992) {
      margin-right: -20px;
    }
  }
  .deco-line--left {
    &:before {
      left: calc(50% - 55px);
      @include minMedia(567) {
        left: 0;
      }
    }
  }
  .advantages-item {
    padding: 5px 0;
    @include minMedia(992) {
      padding: 5px;
    }
    &__inner {
      position: relative;
      height: auto;
      background-color: #fff;
      padding: 1.5rem 0.5rem;
      padding-left: 70px;
      @include minMedia(576) {
        padding: 1.5rem 1rem;
        padding-left: 85px;
      }
      @include minMedia(992) {
        padding: 2rem 1rem;
        padding-left: 85px;
      }
      @include minMedia(1200) {
        min-height: 215px;
        padding: 2rem 1rem 0.5rem 1rem;
        padding-left: 85px;
      }
    }
    &__image {
      position: absolute;
      top: 25px;
      left: 15px;
      @include minMedia(576) {
        left: 20px;
        top: 25px;
      }
      @include minMedia(992) {
        top: 35px;
      }
    }
  }
}

// ======================================================================

.s-autopark {
  height: auto;
  .deco-line--left {
    &:before {
      left: calc(50% - 55px);
      @include minMedia(567) {
        left: 0;
      }
    }
  }
}

.autopark-item {
  position: relative;
  padding: 0;
  @include minMedia(768) {
    padding: 1rem;
    padding-left: 0;
  }
}

.autopark-wrap {
  width: 100%;
  display: block;
  @include minMedia(400) {
    display: flex;
    flex-wrap: wrap;
  }
  @include minMedia(1200) {
    min-width: 700px;
  }
}

.autopark-img-block {
  overflow: hidden;
  position: relative;
  width: 100%;
  padding-top: 0;
  @include minMedia(400) {
    width: 50%;
    padding-top: 48%;
  }
  @include minMedia(1200) {
    width: 33%;
    padding-top: 32%;
  }
  img {
    width: 100%;
    height: auto;
    position: relative;
    @include minMedia(400) {
      position: absolute;
      top: 0;
    }
  }
  &.long-block {
    width: 100%;
    @include minMedia(1200) {
      width: 66%;
      padding-top: 30%;
    }
  }
}

// ======================================================================

.s-cargo-individuals {
  height: auto;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: theme_color("blue");
    @include minMedia(992) {
      width: 50%;
    }
  }
  .main-title {
    position: relative;
    padding-bottom: 1rem;
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      max-width: none;
      height: 2px;
      background: #fff;
      left: 0;
      bottom: 0;
      @include minMedia(576) {
        width: 70%;
        max-width: 470px;
      }
    }
  }
  .image-initial {
    margin-left: -150px;
    margin-top: 35px;
    @include minMedia(1200) {
      margin-left: -205px;
      margin-top: 35px;
    }
  }
}

// ======================================================================

.s-without-risk {
  height: auto;
  padding: 35px 0 30px 0;
  @include minMedia(576) {
    padding: 45px 0 45px 0;
  }
  @include minMedia(992) {
    padding: 75px 0 100px 0;
  }
  .deco-line--left {
    &:before {
      left: calc(50% - 55px);
      @include minMedia(567) {
        left: 0;
      }
    }
  }
  .h2 {
    br {
      display: none;
      @include minMedia(576) {
        display: inline-block;
      }
    }
  }
  .without-item {
    padding: 5px 0;
    @include minMedia(992) {
      padding: 5px;
    }
    &__inner {
      position: relative;
      background-color: #fff;
      min-height: 95px;
      padding: 0;
      padding-left: 70px;
      padding-right: 0.5rem;
      display: flex;
      align-items: center;
      flex-direction: row;
      @include minMedia(576) {
        padding-left: 85px;
      }
      @include minMedia(992) {
        padding: 0;
        padding-left: 85px;
        padding-right: 0.5rem;
      }
      @include minMedia(1200) {
        display: block;
        padding: 2rem 2rem;
        min-height: 200px;
      }
      br {
        display: none;
        @include minMedia(1200) {
          display: inline-block;
        }
      }
    }
    &__image {
      position: absolute;
      top: calc(50% - 21.5px);
      left: 15px;
      @include minMedia(576) {
        left: 20px;
      }
      @include minMedia(1200) {
        position: relative;
        top: 0;
        left: 0;
      }
    }
  }
}

// ======================================================================

.s-about {
  height: auto;
  padding-bottom: 0;
  @include minMedia(576) {
    padding-bottom: 40px;
  }
  @include minMedia(992) {
    padding-bottom: 90px;
  }
  .deco-line--left {
    &:before {
      left: calc(50% - 55px);
      @include minMedia(567) {
        left: 0;
      }
    }
  }
  .inner-block {
    &:before {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      width: 0;
      height: 100%;
      background: theme_color("blue");
      @include minMedia(992) {
        width: 50%;
      }
    }
  }
  .img-wrap {
    position: absolute;
    top: -140px;
    left: 70px;
  }
  .inner-left {
    padding-bottom: 2rem;
    @include minMedia(992) {
      padding-bottom: 5rem;
      padding-right: 4rem;
    }
  }
  .banner-wrap {
    width: 100%;
    margin-top: -20px;
    @include minMedia(992) {
      margin-top: 0;
      width: 100%;
    }
    @include minMedia(1200) {
      width: 70%;
    }
  }
  .banner-item {
    padding: 5px;
    width: 50%;
    height: auto;
    @include minMedia(992) {
      padding: 5px;
      width: 180px;
    }
    .banner-content {
      background-color: #fff;
      padding: 28px 14px;
      min-height: 115px;
      &:before {
        content: "";
        display: block;
        width: 36px;
        height: 2px;
        background: #c4000c;
        margin-bottom: 14px;
      }
    }
  }
}

// ======================================================================

.s-search {
  height: auto;
  .h2 {
    position: relative;
    font-size: 16px;
    @include minMedia(768) {
      font-size: font_size_rem(22px);
    }
    @include minMedia(992) {
      font-size: font_size_rem(28px);
    }
    @include minMedia(1200) {
      font-size: font_size_rem(32px);
    }
  }
  .deco-line--top {
    &:before {
      top: -1.5rem;
    }
  }
}

// ======================================================================

.s-choice {
  height: auto;
  padding: 35px 0 30px 0;
  @include minMedia(576) {
    padding: 45px 0 45px 0;
  }
  @include minMedia(992) {
    padding: 75px 0 115px 0;
  }
}

// ======================================================================

.s-online-order {
  height: auto;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: theme_color("blue");
    @include minMedia(992) {
      width: 40%;
    }
  }
  .image-initial {
    margin-left: -120px;
    margin-top: 3rem;
    @include minMedia(1200) {
      margin-left: -153px;
    }
  }
}

// ======================================================================

.s-contacts {
  height: auto;
  padding: 35px 0 30px 0;
  @include minMedia(576) {
    padding: 45px 0 35px 0;
  }
}

// ======================================================================
