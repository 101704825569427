section,
.scroll-wrap {
  position: relative;
  .next_scroll {
    position: absolute;
    width: 0;
    left: 0;
    top: 0;
  }
}

.section {
  padding: 35px 0 30px 0;
  @include minMedia(576) {
    padding: 45px 0 45px 0;
  }
  @include minMedia(992) {
    padding: 75px 0 60px 0;
  }
}
