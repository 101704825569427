button {
  display: inline-block;
  border: none;
  color: inherit;
  cursor: pointer;
  font: inherit;
  background: none;
  position: relative;
  font: inherit;
}

.btn {
  @extend button;
  text-transform: uppercase;
  color: #fff;
  font-size: 16px;
  font-family: "Opensans";
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.2px;
  padding: 22px 0;
  width: 100%;
  max-width: 320px;
  @include minMedia(400) {
    padding: 22px 18px;
    width: auto;
  }
  &--order {
    @extend .btn;
    background: theme_color(brand);
  }
  &--order-blue {
    @extend .btn;
    background: theme_color("blue");
  }
  &--modal-order {
    @extend .btn;
    background: theme_color(brand);
    padding: 22px 0;
    width: 100%;
    text-align: center;
  }
}
