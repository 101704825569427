$styles: (
  ".vam": (
    "style-name": "vertical-align",
    "style-val": "middle"
  ),
  ".lh-1": (
    "style-name": "line-height",
    "style-val": "1"
  ),
  ".lh-125": (
    "style-name": "line-height",
    "style-val": "1.25"
  ),
  ".lh-130": (
    "style-name": "line-height",
    "style-val": "1.3"
  ),
  ".lh-2": (
    "style-name": "line-height",
    "style-val": "2"
  ),
  ".fs-base": (
    "style-name": "font-size",
    "style-val": 1rem
  ),
  ".fs-12": (
    "style-name": "font-size",
    "style-val": font_size_rem(12px)
  ),
  ".fs-14": (
    "style-name": "font-size",
    "style-val": font_size_rem(14px)
  ),
  ".fs-18": (
    "style-name": "font-size",
    "style-val": font_size_rem(18px)
  ),
  ".fs-20": (
    "style-name": "font-size",
    "style-val": font_size_rem(20px)
  ),
  ".fs-22": (
    "style-name": "font-size",
    "style-val": font_size_rem(22px)
  ),
  ".fs-24": (
    "style-name": "font-size",
    "style-val": font_size_rem(24px)
  ),
  ".fs-26": (
    "style-name": "font-size",
    "style-val": font_size_rem(26px)
  ),
  ".fs-28": (
    "style-name": "font-size",
    "style-val": font_size_rem(28px)
  ),
  ".fs-30": (
    "style-name": "font-size",
    "style-val": font_size_rem(30px)
  ),
  ".fs-40": (
    "style-name": "font-size",
    "style-val": font_size_rem(40px)
  ),
  ".font-light": (
    "style-name": "font-weight",
    "style-val": "300"
  ),
  ".font-regular": (
    "style-name": "font-weight",
    "style-val": "400"
  ),
  ".font-medium": (
    "style-name": "font-weight",
    "style-val": "500"
  ),
  ".font-semibold": (
    "style-name": "font-weight",
    "style-val": "600"
  ),
  ".font-bold": (
    "style-name": "font-weight",
    "style-val": "700"
  ),
  ".font-italic": (
    "style-name": "font-style",
    "style-val": "italic"
  ),
  ".font-opensans": (
    "style-name": "font-family",
    "style-val": "Opensans"
  ),
  ".font-montserrat": (
    "style-name": "font-family",
    "style-val": "Montserrat"
  )
);
@each $key, $style-name in $styles {
  #{$key} {
    #{map_get($style-name, 'style-name')}: #{map_get($style-name, "style-val")};
  }
}

@each $bp, $val in $grid-breakpoints {
  @if $bp != "xs" {
    @each $key, $style-name in $styles {
      #{$key}--#{$bp} {
        @include minMedia($val) {
          #{map_get($style-name, 'style-name')}: #{map_get($style-name, "style-val")};
        }
      }
    }
  }
}
