.header {
  height: auto;
  border-bottom: none;
  @include minMedia(576) {
    border-bottom: 1px solid #000;
  }
  .header-content {
    height: auto;
    min-height: 70px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
  }
  &__item {
    align-items: center;
  }
  .logo-wrap {
    display: inline-block;
    padding-top: 8px;
    @include minMedia(400) {
      padding-top: 0;
    }
  }
  .image-initial {
    max-width: 200px;
    @include minMedia(400) {
      max-width: none;
    }
  }
}
