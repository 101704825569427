.menu-mobil {
  margin: 0;
  padding: 0;
  opacity: 0;
  height: 0;
  transition: all 0.5s ease;
  overflow: hidden;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}

.menu-open {
  .menu-mobil {
    padding: 1rem 0;
    height: auto;
    opacity: 1;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  }
}
