$modal-padding-y: 1.5rem;
.overlay {
  visibility: hidden;
  backface-visibility: hidden;
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 100;
  overflow: auto;
  background: rgba(#000, 0.6);
  &.is-visible {
    visibility: visible;
  }
}
.modal {
  visibility: hidden;
  backface-visibility: hidden;
  padding: $modal-padding-y 0;
  z-index: 101;
  position: fixed;
  left: 0;
  right: 0;
  top: 25px;
  margin: auto;
  pointer-events: none;
  max-height: 100vh;
  overflow-y: scroll;
  transition: all 0.3s;
  transform: translateY(-5%);
  opacity: 0;
  @include minMedia(576) {
    padding: $modal-padding-y 20px;
    overflow: visible;
  }
  &__inner {
    width: 100%;
    max-width: 576px;
    margin: 0 auto;
    background: #fff;
    position: relative;
    padding: 3rem 0;
    border-radius: none;
    height: auto;
    min-height: 550px;
    overflow: hidden;
    @include minMedia(576) {
      padding: 0;
      height: 550px;
    }
    @include minMedia(992) {
      box-shadow: 0 19px 34.6px 3.4px rgba(0, 0, 0, 0.15);
      max-width: 970px;
    }
    .form-wrap {
      width: 100%;
      @include minMedia(992) {
        width: 50%;
        padding-left: 20px;
      }
    }
    .img-wrap {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 50%;
      background-color: #fff;
      img {
        @include minMedia(992) {
          margin-left: -40px;
        }
        @include minMedia(1200) {
          margin-left: -20px;
        }
      }
    }
  }
  &.is-visible {
    visibility: visible;
    pointer-events: initial;
    transform: translateY(0);
    opacity: 1;
  }
  &--default {
    width: 100%;
    max-width: 576px;
    @include minMedia(992) {
      max-width: 970px;
    }
  }
  &__title {
    width: 100%;
    color: theme_color("brand");
    font-size: 1.75em;
    font-weight: 700;
    text-align: center;
  }
  &__subtitle {
    font-weight: 700;
    text-align: center;
    color: theme_color("brand");
  }
  &__close {
    position: absolute;
    width: $modal-padding-y;
    height: $modal-padding-y;
    top: 0;
    right: 0;
    cursor: pointer;
    font-size: 0;
    display: block;
    z-index: 2;
    padding: 0;
    @include minMedia(768) {
      top: -10px;
      right: 0;
    }
    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 2px;
      background: #fff;
      top: 50%;
      margin-top: -1px;
      left: 0;
      transform-origin: center;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
  &__inner &__close {
    top: 10px;
    right: 10px;
  }
}
