.deco-line {
  position: relative;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  &:before {
    content: "";
    position: absolute;
    width: 70%;
    max-width: 470px;
    height: 2px;
    background: #c4000c;
    left: 0;
    bottom: 0;
  }
  &--center {
    @extend .deco-line;
    &:before {
      width: 140px;
      left: calc(50% - 70px);
    }
  }
  &--left {
    @extend .deco-line;
    &:before {
      width: 110px;
    }
  }
  &--top {
    @extend .deco-line;
    padding-bottom: 0;
    margin-bottom: 0;
    &:before {
      width: 140px;
      top: -1rem;
      left: calc(50% - 70px);
      @include minMedia(576) {
        left: 0;
      }
    }
  }
  &--white {
    @extend .deco-line;
    &:before {
      background: #fff;
    }
  }
}
