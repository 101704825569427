.color-white {
  color: theme_text_color("white");
}

.color-blue {
  color: theme_text_color("blue");
}

.color-black {
  color: #000;
}
