.bg--transparent {
  background: transparent;
}
.bg-white {
  background: theme_color("white");
}
.bg-gray {
  background: theme_color("gray");
}
.bg-blue {
  background: theme_color("blue");
}
.bg-black {
  background: theme_color("black");
}
.bg-dark {
  background: theme_color("dark");
}

.bg-overlay {
  background-color: rgba(0, 0, 0, 0.7);
}

.bg-cover {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.link-shadow {
  display: inline-block;
  transition: all 0.35s ease;
  &:hover {
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.22);
  }
}

.bg-opacity {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.88);
    z-index: -1;
  }
}
