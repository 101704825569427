$select-background: #fff;
$select-color: inherit;

.select-hidden {
  display: none;
  visibility: hidden;
  padding-right: 10px;
}
.select {
  cursor: pointer;
  position: relative;
  font-size: inherit;
  color: #000;
  width: 100%;
  @include minMedia(768) {
    width: 50%;
  }
}
.select-styled {
  background-color: $select-background;
  padding: 5px 24px 5px 10px;
  border-radius: 2px;
  border: 1px solid #e3e3e3;
  transition: all 0.2s ease-in;
  i {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    transform-origin: 50%;
    color: #bdbebf;
  }
  span {
    font-family: "Opensans";
    font-size: 16px;
    font-weight: 400;
  }
  &:hover {
    background-color: darken($select-background, 2);
  }
  &:active,
  &.active {
    background-color: darken($select-background, 5);
    &:after {
      top: 9px;
      border-color: transparent transparent $select-color transparent;
    }
    i {
      transform: translateY(-50%) rotateX(180deg);
    }
  }
}

.select-options {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 999;
  margin: 0;
  padding: 0;
  margin-top: -3px;
  list-style: none;
  background-color: darken($select-background, 5);
  li {
    margin: 0;
    padding: 12px 10px;
    //text-indent: 15px;
    border-top: 1px solid darken($select-background, 10);
    @include transition(all 0.15s ease-in);
    &:hover {
      background: $select-color;
    }
    &[rel="hide"] {
      display: none;
    }
    &:first-child {
      display: none;
    }
  }
}
