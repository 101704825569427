.client-slider {
  .swiper-slide {
    height: 120px;
  }
  .client-button {
    position: relative;
    cursor: pointer;
    z-index: 100;
  }
}
