@import "typography";
* {
  outline: none;
  box-sizing: border-box;
}
img {
  max-width: 100%;
}
.block-image {
  display: block;
}
a {
  color: inherit;
  text-decoration: none;
}
body {
  background: theme_color("body");
}
.section {
  position: relative;
}
.input-error {
  display: none;
}
.pointer {
  cursor: pointer;
}

.inline-block {
  display: inline-block;
}
.page-wrap {
  position: relative;
  overflow: hidden;
}

h1,
h2,
h3 {
  margin: 0;
}

p {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
  li {
    //list-style: none;
  }
}

.svg-default {
  vertical-align: middle;
  line-height: 1;
}
