.btn-hover {
  transition: all 0.35s ease;
  color: #fff;
  &:hover {
    background: theme_color("brand-hover");
    border-color: theme_color("brand-hover");
    color: #fff;
  }
  &:active {
    background: theme_color("brand-active");
    border-color: theme_color("brand-active");
    color: #fff;
  }
  &--blue {
    transition: all 0.35s ease;
    color: #fff;
    &:hover {
      background: theme_color("blue-hover");
      border-color: theme_color("blue-hover");
      color: #fff;
    }
    &:active {
      background: theme_color("blue-active");
      border-color: theme_color("blue-active");
      color: #fff;
    }
  }
}
