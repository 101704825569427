.max-1200 {
  display: block;
  @include minMedia(1200) {
    display: none;
  }
}

.max-992 {
  display: block;
  @include minMedia(992) {
    display: none;
  }
}
.max-768 {
  display: block;
  @include minMedia(768) {
    display: none;
  }
}
.max-576 {
  display: block;
  @include minMedia(576) {
    display: none;
  }
}

.max-flex-576 {
  display: flex;
  @include minMedia(576) {
    display: none;
  }
}

.min-576,
.min-768,
.min-992,
.min-1200,
.min-inline-576,
.min-inline-768,
.min-inline-992,
.min-inline-1200,
.min-flex-576,
.min-flex-992 {
  display: none;
}

.min-576 {
  @include minMedia(576) {
    display: block;
  }
}
.min-768 {
  @include minMedia(768) {
    display: block;
  }
}
.min-992 {
  @include minMedia(992) {
    display: block;
  }
}
.min-1200 {
  @include minMedia(1200) {
    display: block;
  }
}
.min-inline-576 {
  @include minMedia(576) {
    display: inline;
  }
}
.min-inline-768 {
  @include minMedia(768) {
    display: inline;
  }
}
.min-inline-992 {
  @include minMedia(992) {
    display: inline;
  }
}
.min-inline-1200 {
  @include minMedia(1200) {
    display: inline;
  }
}
.min-flex-992 {
  @include minMedia(992) {
    display: flex;
  }
}
.min-flex-768 {
  display: block;
  @include minMedia(768) {
    display: flex;
  }
}
.min-flex-576 {
  @include minMedia(576) {
    display: flex;
  }
}
