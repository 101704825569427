// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/// Regular font family
/// @type List
//$text-font-stack: "Candara", sans-serif !default;
$text-font-stack: "Opensans", sans-serif !default;

$base-font-size: 16px !default;

$base-line-height: 1.375 !default;

/// Code (monospace) font family
/// @type List
$code-font-stack: "Courier New", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Monaco", monospace !default;

/// Container's maximum width
/// @type Length
$max-width: 1180px !default;

// Bootstrap Breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

// Bootstrap container width
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1170px
) !default;

// Bootstrap columns count
$grid-columns: 12 !default;
// Bootstrap space between columns
$grid-gutter-width-base: 30px !default;
$grid-gutter-widths: (
  xs: $grid-gutter-width-base,
  sm: $grid-gutter-width-base,
  md: $grid-gutter-width-base,
  lg: $grid-gutter-width-base,
  xl: $grid-gutter-width-base
) !default;

// Grid column gap
// Column padding-left-right, Row margin-left-right,
// container-fluid padding-left-right, Container padding-left-right
$grid-col-gap: 15px;

$font-weight: (
  "light": 300,
  "regular": 400,
  "semibold": 600,
  "bold": 700,
  "extrabold": 800
);

$brand: #c4000c !default;

$text-color: (
  "primary": #000000,
  "accent": $brand,
  "white": #fff,
  "blue": #053e7c,
  "error": red
) !default;

$theme-color: (
  "body": #f5f5f5,
  "white": #fff,
  "blue": #053e7c,
  "gray": #ebebeb,
  "brand": $brand,
  "brand-hover": darken($brand, 10%),
  "brand-active": darken($brand, 20%),
  "blue-hover": darken(#053e7c, 10%),
  "blue-active": darken(#053e7c, 20%),
  "black": #000,
  "dark": #111
) !default;
